import styled from '@emotion/styled';

import { Heading } from '@/components/common/MarkUp';
import { bodyLarge, subheadingSmall } from '@/styles/typography';

import type { DecorativeColourTokenType } from '@/types/colours';

export const buildDataSet = (data, optionals = {}) => {
  (data.labels = data.map((d) => d.label)),
    (data.datasets = [
      {
        data: data.map((d) => d.value),
        backgroundColor: data.map((d) => d.colour.value),
        ...optionals,
      },
    ]);

  return data;
};

export const Legend = ({ data }) => {
  return (
    <LegendContainer>
      {data?.map((d) => (
        <LegendLi key={d.label}>
          <Hexagon colour={d.colour.token} />
          <span>
            {d.label} <strong>{d.value}%</strong>
          </span>
        </LegendLi>
      ))}
    </LegendContainer>
  );
};

export const LegendContainer = styled.ul`
  list-style: none;
  margin-left: var(--spacing-xx-small);
  display: flex;
  flex-direction: column;
`;

const LegendLi = styled.li`
  display: flex;
  gap: var(--spacing-xxx-small);
  align-items: center;
  ${bodyLarge}

  margin-bottom: var(--spacing-xx-small);

  strong {
    font-weight: var(--font-weight-semibold);
  }
`;

interface HexagonProps {
  colour: DecorativeColourTokenType;
}

const Hexagon = styled.div<HexagonProps>`
  position: relative;
  width: 15px;
  height: 8.66px;
  background-color: ${(props) => `var(${props.colour})`};
  margin: 4.33px 0;
  margin-right: 8px;
  padding-right: 15px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
  }

  &:before {
    bottom: 100%;
    border-bottom: 4.33px solid ${(props) => `var(${props.colour})`};
  }

  :after {
    top: 100%;
    width: 0;
    border-top: 4.33px solid ${(props) => `var(${props.colour})`};
  }
`;

export const ChartContainer = styled.div<{ backgroundColourToken }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  width: 100%;
  border-radius: var(--radius-s);
  padding: var(--spacing-300);
  background-color: ${({ backgroundColourToken }) =>
    backgroundColourToken
      ? `var(${backgroundColourToken});`
      : `var(--surface-cool-base);`};
`;

export const OuterContainer = styled.div<{ hasLabel: boolean }>`
  display: grid;

  grid-template-rows: ${({ hasLabel }) => (hasLabel ? '1fr 6fr' : '1fr')};
  grid-template-columns: minmax(200px, 320px);
`;

export const ChartCaption = styled(Heading)`
  ${subheadingSmall}
  align-self: end;
  margin-bottom: var(--spacing-small);
`;
