import { listColours } from '../../../lib/colourUtils';

// Note:
// - Value is passed into sanity. This cannot be a variable.
// - Token is passed into the components. This should match up to the Value.
export const defaultDecorativeColours = [
  {
    title: 'ultraviolet / ultraviolet-500',
    value: 'rgb(74, 95, 247)',
    token: '--decorative-ultraviolet',
  },
  {
    title: 'dark-ultraviolet / ultraviolet-900',
    value: 'rgb(43, 50, 109)',
    token: '--decorative-dark-ultraviolet',
  },
  {
    title: 'starlight / ultraviolet-100',
    value: 'rgb(230, 232, 254)',
    token: '--decorative-starlight',
  },
  {
    title: 'eclipse / eclipse-200',
    value: 'rgb(221, 252, 157)',
    token: '--decorative-eclipse',
  },
  {
    title: 'dark-eclipse / eclipse-500',
    value: 'rgb(214, 238, 64)',
    token: '--decorative-dark-eclipse',
  },
  {
    title: 'dark-sunbeam / sunbeam-200',
    value: 'rgb(252, 214, 157)',
    token: '--decorative-dark-sunbeam',
  },
  {
    title: 'sunbeam / sunbeam-500',
    value: 'rgb(251, 179, 72)',
    token: '--decorative-sunbeam',
  },
  {
    title: 'galaxy / galaxy-500',
    value: 'rgb(132, 35, 255)',
    token: '--decorative-galaxy',
  },
  {
    title: 'soft-galaxy / ultraviolet-700',
    value: 'rgb(129, 137, 191)',
    token: '--decorative-soft-galaxy',
  },
  {
    title: 'green / green-400',
    value: 'rgb(73, 226, 132)',
    token: '--decorative-green',
  },
  {
    title: 'green-tint / green-100',
    value: 'rgb(221, 251, 220)',
    token: '--decorative-green-tint',
  },
  {
    title: 'red / red-500',
    value: 'rgb(241, 79, 65)',
    token: '--decorative-red',
  },
  {
    title: 'red-tint / red-100',
    value: 'rgb(255, 247, 176)',
    token: '--decorative-red-tint',
  },
  {
    title: 'blue / blue-400',
    value: 'rgb(81, 166, 244)',
    token: '--decorative-blue',
  },
  {
    title: 'blue-tint / blue-100',
    value: 'rgb(210, 233, 255)',
    token: '--decorative-blue-tint',
  },
  {
    title: 'yellow / yellow-400',
    value: 'rgb(255, 214, 85)',
    token: '--decorative-yellow',
  },
  {
    title: 'yellow-tint / yellow-100',
    value: 'rgb(255, 247, 176)',
    token: '--decorative-yellow-tint',
  },
];

const decorativeColours = (props = {}, specificColours = []) => {
  return {
    name: 'decorativeColour',
    title: 'Decorative Colour',
    type: 'colorlist',
    options: {
      list: listColours(specificColours, defaultDecorativeColours),
    },
    ...props,
  };
};

export default decorativeColours;
