import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Charts from 'chart.js/auto';

import { screen } from '@/components/common/breakpoints';
import { HeadingLevel } from '@/components/common/MarkUp';

import {
  buildDataSet,
  ChartCaption,
  ChartContainer,
  Legend,
  OuterContainer,
} from './common';

export const DoughnutChart = ({
  label = '',
  data = [],
  backgroundColourToken,
}) => {
  const canvasEl = useRef(null);

  useEffect(() => {
    const chart = new Charts(canvasEl.current, {
      type: 'doughnut',
      options: {
        borderWidth: 0,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: buildDataSet(data),
    });

    return () => chart.destroy();
  });

  return (
    <OuterContainer hasLabel={!!label}>
      <HeadingLevel>
        {!!label && <ChartCaption>{label}</ChartCaption>}
        <StyledChartContainer backgroundColourToken={backgroundColourToken}>
          <DoughnutContainer>
            <canvas ref={canvasEl} />
          </DoughnutContainer>
          <Legend data={data} />
        </StyledChartContainer>
      </HeadingLevel>
    </OuterContainer>
  );
};

const StyledChartContainer = styled(ChartContainer)`
  align-items: flex-start;
  ${screen.sm} {
    padding: var(--spacing-small) var(--spacing-small) var(--spacing-500);
  }
`;

const DoughnutContainer = styled.div`
  max-width: 250px;
  display: flex;
  align-items: center;
  align-self: center;
  padding-top: var(--spacing-small);
  width: 100%;
`;
