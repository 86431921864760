import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Charts from 'chart.js/auto';

import { screen } from '@/components/common/breakpoints';
import { HeadingLevel } from '@/components/common/MarkUp';

import {
  buildDataSet,
  ChartCaption,
  ChartContainer,
  Legend,
  OuterContainer,
} from './common';

export const BarChart = ({ label = '', data = [], backgroundColourToken }) => {
  const canvasEl = useRef(null);
  const barThickness = 130;

  useEffect(() => {
    const chart = new Charts(canvasEl.current || 'test', {
      type: 'bar',
      options: {
        tooltips: {
          titleFontSize: 9,
          bodyFontSize: 9,
        },
        layout: {
          padding: {
            right: 10,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => '',
              label(context) {
                const tooltipLabel = `${context.label}: ${context.parsed.x}`;
                return wrapTooltip(tooltipLabel, 35);
              },
            },
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
      data: buildDataSet(data, {
        barThickness: barThickness / data.length,
      }),
    });

    return () => chart.destroy();
  });

  return (
    <OuterContainer hasLabel={!!label}>
      <HeadingLevel>
        {!!label && <ChartCaption>{label}</ChartCaption>}
        <StyledChartContainer backgroundColourToken={backgroundColourToken}>
          <BarContainer>
            <canvas ref={canvasEl} />
          </BarContainer>
          <Legend data={data} />
        </StyledChartContainer>
      </HeadingLevel>
    </OuterContainer>
  );
};

const wrapTooltip = (str, limit) => {
  const words = str.split(' ');
  const aux = [];
  let concat = [];

  for (let i = 0; i < words.length; i += 1) {
    concat.push(words[i]);
    const join = concat.join(' ');
    if (join.length > limit) {
      aux.push(join);
      concat = [];
    }
  }

  if (concat.length) {
    aux.push(concat.join(' ').trim());
  }

  return aux;
};

const StyledChartContainer = styled(ChartContainer)`
  position: relative;
  ${screen.sm} {
    padding: var(--spacing-small);
  }
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  min-height: 200px;
  padding-top: var(--spacing-small);
  width: 100%;
`;
