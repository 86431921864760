import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { HeadingLevel } from '@/components/common/MarkUp';
import { Section } from '@/components/layout/Section';
import { getChildBackgroundColourToken } from '@/lib/colourUtils';
import { getRandomFromArray } from '@/lib/utils';
import { defaultDecorativeColours } from '@/sanity/schemas/objects/colourObjects/decorativeColours';

import { BarChart } from './BarChart';
import { DoughnutChart } from './DoughnutChart';

import type { BackgroundColourType } from '@/types/colours';
import type { SanityChartsGraphsType } from '@/types/sanity';

export interface ChartsProps extends SanityChartsGraphsType {
  _key?: string;
  breakout?: boolean;
  verticalPadding?: boolean;
  backgroundColour?: BackgroundColourType;
}

export const Chart = ({
  charts,
  heading,
  backgroundColour,
  paddingAdjustment = 'default',
  breakout = false,
  verticalPadding,
}: ChartsProps) => {
  const backgroundColourToken =
    backgroundColour?.token || '--background-subtle';
  const updatedCharts = charts.map((chart) => {
    const dataNum = chart.data.length;
    const defaultColours = getRandomFromArray(
      defaultDecorativeColours,
      dataNum,
    );
    chart.data.map((d, index) =>
      typeof d.colour === 'object' ? null : (d.colour = defaultColours[index]),
    );

    return chart;
  });
  const childBackgroundColourToken = getChildBackgroundColourToken(
    backgroundColourToken,
    '--background-warm-base',
  );
  return (
    <Section
      breakout={breakout}
      backgroundColour={backgroundColourToken}
      verticalPadding={
        verticalPadding === false ? false : verticalPadding || paddingAdjustment
      }
      className="charts"
    >
      <HeadingLevel>
        {heading && <CaptionHeadingDescription heading={heading} />}
        <ChartsContainer>
          {updatedCharts.map((chart) => {
            switch (chart.type) {
              case 'doughtnut':
                return (
                  <DoughnutChart
                    key={chart._key}
                    {...chart}
                    backgroundColourToken={childBackgroundColourToken}
                  />
                );
              case 'bar':
                return (
                  <BarChart
                    key={chart._key}
                    {...chart}
                    backgroundColourToken={childBackgroundColourToken}
                  />
                );
              default:
                return null;
            }
          })}
        </ChartsContainer>
      </HeadingLevel>
    </Section>
  );
};

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 300px));
  justify-content: center;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    justify-content: space-between;
  }

  justify-items: center;
  padding-top: var(--spacing-x-large);
  grid-gap: var(--spacing-200);

  ${screen.sm} {
    justify-content: center;
  }

  ${screen.md} {
    grid-gap: var(--spacing-500);
  }
`;
